// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyDPUC2yqZl3BEUPTagsxuB8yAJGpNV9Hd8",
  authDomain: "umuthombowolwazi.firebaseapp.com",
  databaseURL: "https://umuthombowolwazi.firebaseio.com",
  projectId: "umuthombowolwazi",
  storageBucket: "umuthombowolwazi.appspot.com",
  messagingSenderId: "103191480200",
  appId: "1:103191480200:web:43c7471cb5b327e098dc3d",
  measurementId: "G-CVHZM6TWER"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
